import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

import { InvestorList } from '@/models/investor';
import { CompanyList } from '@/models/company';
import { AcquisitionList } from '@/models/Acquisition';
import Pagination from '@/components/Filters/Pagination';

interface Props {
  items: CompanyList | InvestorList | AcquisitionList;
  label: string;
}

const PaginationRow = ({ items, label }: Props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { isAuthenticated } = useAuth0();

  return mobile ? (
    <Box sx={{ display: 'flex', flexDirection: 'column' }} pb={1}>
      {items && items.meta && (
        <>
          <Typography
            width={'max-content'}
            variant={'h5'}
            data-testid="companies-found-text"
          >
            {`Showing: ${items.meta.total_items} ${label}`}
          </Typography>

          {isAuthenticated && (
            <Pagination
              totalItems={items.meta.total_items}
              lastItem={items.meta.last}
            />
          )}
        </>
      )}
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row'
      }}
      my={isAuthenticated ? 1 : 2}
    >
      {items && items.meta && (
        <>
          <Typography
            width={'max-content'}
            variant={'h4'}
            data-testid="companies-found-text"
          >
            {`Showing: ${items.meta.total_items} ${label}`}
          </Typography>

          {isAuthenticated && (
            <Pagination
              totalItems={items.meta.total_items}
              lastItem={items.meta.last}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default PaginationRow;
