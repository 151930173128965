import { useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  Popover,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';

import { useRouterParams } from '@/hooks/useRouterParams';
import { OptionsProps } from '@/components/Filters/types';
import { useFiltersHelpers } from '@/components/Filters/hooks';
import ActionButtons from '@/components/Filters/ActionButtons';

const WebVersion = ({ label, filterId, filterName, data }) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [allFilterOptions, setAllFilterOptions] =
    useState<OptionsProps[]>(data);

  const [paginatedFilterOptions, setPaginatedFilterOptions] = useState<
    OptionsProps[]
  >(allFilterOptions.slice(0, 70));

  const { clearParams } = useRouterParams();
  const {
    saveFilterParamsToUrl,
    addCheckedToOptions,
    checkOption,
    filterOptionsByQuery
  } = useFiltersHelpers();

  const handleSearchQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const loadMoreOptionsOnScroll = (event) => {
    const { target } = event;

    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      setPaginatedFilterOptions((options) => [
        ...options,
        ...allFilterOptions.slice(options.length, options.length + 70)
      ]);
    }
  };

  const handleFilterButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const countCheckedOptions = useMemo(
    () =>
      allFilterOptions.reduce((acc, currentValue) => {
        return currentValue.checked ? acc + 1 : acc;
      }, 0),
    [allFilterOptions]
  );

  useEffect(() => {
    setAllFilterOptions(addCheckedToOptions(data, filterName));
  }, [addCheckedToOptions, data, filterName]);

  useEffect(() => {
    setPaginatedFilterOptions(allFilterOptions.slice(0, 30));
  }, [allFilterOptions]);

  useEffect(() => {
    if (searchQuery) {
      setPaginatedFilterOptions(
        filterOptionsByQuery(allFilterOptions, searchQuery)
      );
    } else {
      setPaginatedFilterOptions(allFilterOptions.slice(0, 70));
    }
  }, [allFilterOptions, filterOptionsByQuery, searchQuery]);

  return (
    <>
      <Button
        data-testid="select-filter-button"
        aria-controls={!!anchorEl ? filterId : undefined}
        aria-haspopup="true"
        aria-expanded={!!anchorEl ? 'true' : undefined}
        variant="outlined"
        disableElevation
        onClick={handleFilterButtonClick}
        endIcon={<KeyboardArrowDownIcon />}
        size="small"
      >
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          {!!countCheckedOptions && (
            <Box
              sx={{
                color: '#333',
                background: '#2233541a',
                width: 20,
                height: 20,
                borderRadius: 10
              }}
            >
              {countCheckedOptions}
            </Box>
          )}

          <Typography variant="h5">{label}</Typography>
        </Stack>
      </Button>

      <Popover
        id={filterId}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box p={2}>
          <TextField
            value={searchQuery}
            onChange={handleSearchQueryChange}
            data-testid={`${filterId}_search`}
            label="Search"
            variant="outlined"
            sx={{ my: 1, width: '100%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
              endAdornment: searchQuery && (
                <InputAdornment position="end">
                  <CloseIcon
                    onClick={() => setSearchQuery('')}
                    sx={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              )
            }}
          />

          <Box
            sx={{ width: 600, height: 400, overflowY: 'scroll' }}
            m={1}
            onScroll={loadMoreOptionsOnScroll}
          >
            <Grid container spacing={1} columns={{ xs: 4 }}>
              {paginatedFilterOptions.map((option) => (
                <Grid item xs={2} key={option.id}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={option.checked}
                          data-testid={`${option.name}_checkbox`}
                        />
                      }
                      onChange={() => {
                        setAllFilterOptions(
                          checkOption(allFilterOptions, option.id)
                        );
                      }}
                      label={option.name}
                    />
                  </FormGroup>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider sx={{ width: '100%', mt: 1, mb: 0.5 }} />

          <ActionButtons
            onClear={() => {
              clearParams(filterName);
              handleFilterClose();
            }}
            onSave={() => {
              saveFilterParamsToUrl(allFilterOptions, filterName);
              handleFilterClose();
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default WebVersion;
