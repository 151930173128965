import { useEffect, useState, useMemo } from 'react';

import {
  Accordion,
  Chip,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  Box,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Divider
} from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

import { useRouterParams } from '@/hooks/useRouterParams';
import { OptionsProps } from '@/components/Filters/types';
import { useFiltersHelpers } from '@/components/Filters/hooks';
import ActionButtons from '@/components/Filters/ActionButtons';

const MobileVersion = ({ label, filterId, filterName, data }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [allFilterOptions, setAllFilterOptions] =
    useState<OptionsProps[]>(data);

  const [paginatedFilterOptions, setPaginatedFilterOptions] = useState<
    OptionsProps[]
  >(allFilterOptions.slice(0, 30));

  const { clearParams } = useRouterParams();
  const {
    saveFilterParamsToUrl,
    addCheckedToOptions,
    checkOption,
    filterOptionsByQuery
  } = useFiltersHelpers();

  const countCheckedOptions = useMemo(
    () =>
      allFilterOptions.reduce((acc, currentValue) => {
        return currentValue.checked ? acc + 1 : acc;
      }, 0),
    [allFilterOptions]
  );

  const handleSearchQueryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchQuery(event.target.value);
  };

  const loadMoreOptionsOnScroll = (event) => {
    const { target } = event;

    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      setPaginatedFilterOptions((options) => [
        ...options,
        ...allFilterOptions.slice(options.length, options.length + 30)
      ]);
    }
  };

  useEffect(() => {
    setAllFilterOptions(addCheckedToOptions(data, filterName));
  }, [addCheckedToOptions, data, filterName]);

  useEffect(() => {
    setPaginatedFilterOptions(allFilterOptions.slice(0, 30));
  }, [allFilterOptions]);

  useEffect(() => {
    if (searchQuery) {
      setPaginatedFilterOptions(
        filterOptionsByQuery(allFilterOptions, searchQuery)
      );
    } else {
      setPaginatedFilterOptions(allFilterOptions.slice(0, 30));
    }
  }, [allFilterOptions, filterOptionsByQuery, searchQuery]);

  return (
    <>
      <Accordion
        expanded={isExpanded}
        onChange={(_event, expanded) => {
          setIsExpanded(expanded);
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{ p: 0 }}
        >
          <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
            {!!countCheckedOptions && (
              <Chip size="small" label={countCheckedOptions} />
            )}

            <Typography variant="h4">{label}</Typography>
          </Stack>
        </AccordionSummary>

        <AccordionDetails sx={{ p: 0, mt: 1 }}>
          <TextField
            value={searchQuery}
            onChange={handleSearchQueryChange}
            data-testid={`${filterId}_search`}
            label="Search"
            variant="outlined"
            size="small"
            sx={{ my: 0, width: '100%' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
              endAdornment: searchQuery && (
                <InputAdornment position="end">
                  <CloseIcon
                    onClick={() => setSearchQuery('')}
                    sx={{ cursor: 'pointer' }}
                  />
                </InputAdornment>
              )
            }}
          />

          <Box
            sx={{ height: 400, overflowY: 'scroll' }}
            m={1}
            onScroll={loadMoreOptionsOnScroll}
          >
            <Grid container spacing={1} columns={{ xs: 2 }}>
              {paginatedFilterOptions.map((option) => (
                <Grid item xs={2} key={`${option.id}`}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={option.checked || false}
                          data-testid={`${option.name}_checkbox`}
                        />
                      }
                      onChange={() => {
                        setAllFilterOptions(
                          checkOption(allFilterOptions, option.id)
                        );
                      }}
                      label={option.name}
                    />
                  </FormGroup>
                </Grid>
              ))}
            </Grid>
          </Box>

          <Divider sx={{ width: '100%', mt: 1, mb: 0.5 }} />

          <ActionButtons
            onClear={() => {
              clearParams(filterName);
              setIsExpanded(false);
            }}
            onSave={() => {
              saveFilterParamsToUrl(allFilterOptions, filterName);
              setIsExpanded(false);
            }}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default MobileVersion;
