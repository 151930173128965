import { useEffect, useState } from 'react';

export const containImage = (url: string): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => {
      resolve(true);
    };
    img.onerror = () => {
      resolve(false);
    };
  });
};

export const useImageCheck = (path: string): string => {
  const [validOrEmptyPath, setValidOrEmptyPath] = useState('');

  useEffect(() => {
    let isSubscribed = true;
    containImage(path).then((result) => {
      if (isSubscribed) {
        setValidOrEmptyPath(result ? path : '');
      }
    });
    return () => {
      isSubscribed = false;
    };
  }, [path]);
  return validOrEmptyPath;
};
