import { useCallback } from 'react';

import { useRouterParams } from '@/hooks/useRouterParams';
import { OptionsProps } from '@/components/Filters/types';

export const useFiltersHelpers = () => {
  const { setParam, hasParam, getParamValue } = useRouterParams();

  const addCheckedToOptions = useCallback(
    (data: OptionsProps[], filterName: string) => {
      if (hasParam(filterName)) {
        const valuesFromUrl = getParamValue(filterName);

        if (Array.isArray(valuesFromUrl)) {
          return data.map((option) => {
            if (valuesFromUrl.includes(option.id)) {
              return { ...option, checked: true };
            }

            return { ...option, checked: false };
          });
        } else {
          return data.map((option) => {
            if (valuesFromUrl === option.id) {
              return { ...option, checked: true };
            }

            return { ...option, checked: false };
          });
        }
      } else {
        return data.map((option) => {
          return { ...option, checked: false };
        });
      }
    },
    [getParamValue, hasParam]
  );

  const saveFilterParamsToUrl = (
    options: OptionsProps[],
    filterName: string
  ) => {
    let checkedOptions: string[] = [];

    options.forEach((option) => {
      if (option.checked) {
        checkedOptions.push(option.id);
      }
    });

    setParam(filterName, checkedOptions);
  };

  const checkOption = (options: OptionsProps[], id: string) => {
    return options.map((option) => {
      if (option.id === id) {
        return {
          ...option,
          checked: !option.checked
        };
      }
      return option;
    });
  };

  const filterOptionsByQuery = useCallback(
    (options: OptionsProps[], searchQuery: string): OptionsProps[] => {
      return options.filter((option) => {
        return option.name
          .toLowerCase()
          .includes(searchQuery.trim().toLowerCase());
      });
    },
    []
  );

  const loadMoreOptionsOnScroll = (
    event,
    options: OptionsProps[],
    optionsPerPage: number
  ) => {
    const { target } = event;

    const scrollTop = target.scrollTop;
    const scrollHeight = target.scrollHeight;
    const clientHeight = target.clientHeight;

    if (scrollTop + clientHeight >= scrollHeight) {
      return [
        ...options,
        ...options.slice(options.length, options.length + optionsPerPage)
      ];
    }
  };

  return {
    addCheckedToOptions,
    saveFilterParamsToUrl,
    checkOption,
    filterOptionsByQuery,
    scrollOptions: loadMoreOptionsOnScroll
  };
};
