import React, { useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash';
import { Box, InputAdornment, TextField } from '@mui/material';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import CloseIcon from '@mui/icons-material/Close';

import { useRouterParams } from '@/hooks/useRouterParams';

interface Props {
  filterId: string;
  filterName: string;
  placeholder: string;
}

const Search = ({ filterId, filterName, placeholder }: Props) => {
  const { getParamValue, setParam, clearParams } = useRouterParams();

  const [query, setQuery] = useState<string | string[] | undefined>(
    getParamValue(filterName) || ''
  );

  const debounceSetParam = useRef(
    debounce((value) => {
      if (value) {
        setParam(filterName, value);
      } else {
        clearParams(filterName);
      }
    }, 700)
  ).current;

  const handleQueryChange = (event) => {
    setQuery(event.target.value);
    debounceSetParam(event.target.value);
  };

  const clearQuery = () => {
    setQuery('');
    clearParams(filterName);
  };

  useEffect(() => {
    return () => {
      debounceSetParam.cancel();
    };
  }, [debounceSetParam]);

  useEffect(() => {
    if (!getParamValue(filterName)) {
      setQuery('');
    }
  }, [filterName, getParamValue]);

  return (
    <Box display="flex" sx={{ mb: 1 }}>
      <TextField
        name={filterId}
        value={query}
        placeholder={placeholder}
        onChange={handleQueryChange}
        fullWidth
        size="small"
        margin="normal"
        variant="outlined"
        sx={{ my: 0 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchTwoToneIcon />
            </InputAdornment>
          ),
          endAdornment: query && (
            <InputAdornment position="end">
              <CloseIcon onClick={clearQuery} sx={{ cursor: 'pointer' }} />
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
};

export default Search;
