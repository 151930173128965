import React, { FC, ReactNode, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import { Box, Container, useTheme } from '@mui/material';

import { User } from '@/models/user';
import Header from '@/layouts/AccentHeaderLayout/Header';
import { useApi } from '@/hooks/useApi';
import Footer from '@/components/Footer';

interface AccentHeaderLayoutProps {
  children?: ReactNode;
}

const Page: FC<AccentHeaderLayoutProps> = ({ children }) => {
  const theme = useTheme();

  const [registerUser, setRegisterUser] = useState(() => {
    const saved = window.localStorage.getItem('registerUser');
    const initialValue = JSON.parse(saved);
    return initialValue || null;
  });

  const { postUser } = useApi();
  const { mutate, isError, isSuccess } = useMutation((user: User) =>
    postUser(user)
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (registerUser) {
      mutate(registerUser);
    }
  }, [registerUser, mutate]);

  useEffect(() => {
    if (isSuccess) {
      window.localStorage.removeItem('registerUser');
      setRegisterUser(null);
    }

    if (isError) {
      enqueueSnackbar('User profile cannot be updated!', {
        variant: 'error'
      });
    }
  }, [enqueueSnackbar, isError, isSuccess]);

  return (
    <>
      <Header />

      <Container maxWidth="xl" sx={{ flex: '1 0 auto' }}>
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            pt: `${theme.header.height}`
          }}
        >
          {children}
        </Box>
      </Container>

      <Box
        sx={{
          flexShrink: 0,
          height: '190px',
          width: '100%'
        }}
      >
        <Container maxWidth="xl">
          <Footer />
        </Container>
      </Box>
    </>
  );
};

Page.propTypes = {
  children: PropTypes.node
};

export default Page;
