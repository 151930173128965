import { ChangeEvent, useEffect, useState } from 'react';

import { TablePagination } from '@mui/material';

import { Limits } from '@/models/company';
import { useRouterParams } from '@/hooks/useRouterParams';
import TablePaginationActions from './TablePaginationActions';

interface Props {
  totalItems: number;
  lastItem: number;
}

const Pagination = ({ totalItems, lastItem }: Props) => {
  const { getParamValue, setParam } = useRouterParams();

  const page: number = Number(getParamValue('page')) || 1;
  const perPage: number =
    Number(localStorage.getItem('per_page')) ||
    Number(getParamValue('per_page')) ||
    10;

  const handlePageChange = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ): void => {
    setParam('page', newPage + 1);
  };

  const handlePerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setParam('per_page', Number(event.target.value));
    localStorage.setItem('per_page', event.target.value);
  };

  const [limits, setLimits] = useState<Limits>({
    limit_items: 0,
    limit_pages: 0
  });

  useEffect(() => {
    if (totalItems && lastItem) {
      setLimits({
        limit_items: totalItems,
        limit_pages: lastItem
      });
    }
  }, [lastItem, totalItems]);

  return (
    <TablePagination
      width="100%"
      labelRowsPerPage="Per page:"
      component="div"
      rowsPerPageOptions={[5, 10, 20, 50]}
      count={limits.limit_items}
      page={page - 1}
      rowsPerPage={perPage}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handlePerPageChange}
      ActionsComponent={TablePaginationActions}
    />
  );
};

export default Pagination;
