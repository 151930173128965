import * as React from 'react';

import { Box, Card, Typography, styled, Stack } from '@mui/material';

const FooterWrapper = styled(Card)(
  ({ theme }) => `
      align-self: end;
      border-radius: 0;
      margin-top: ${theme.spacing(8)};
      @media only screen and (max-width: 340px) {
        margin-top: ${theme.spacing(2)};
      }
`
);

const HeaderWrapper = styled(Card)(
  () => `
      padding: 36px;
      display: flex;
      align-items: center;
      text-align: left;
      justify-content: space-between;

      @media only screen and (max-width: 950px) {
        display: block;
        text-align: center;
      }

      @media only screen and (max-width: 600px) {
        padding: 18px;
      }
`
);

const FooterBottomWrapper = styled(Box)(
  () => `
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`
);

function Footer() {
  return (
    <FooterWrapper className="footer-wrapper">
      <HeaderWrapper>
        <Stack sx={{ width: '100%' }} direction="column">
          <Typography variant="subtitle2">
            Reach out to us in the feedback section or at&nbsp;
            <a href="mailto: andrei@alcottglobal.co">
              andrei@alcottglobal.co
            </a>{' '}
            for any questions or suggestions
          </Typography>
          <FooterBottomWrapper
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%'
            }}
          >
            <Typography variant="subtitle1">
              Copyright &copy; 2022 - Supplify
            </Typography>

            <Typography>
              <a href="https://clearbit.com" target="_blank" rel="noreferrer">
                Logos provided by Clearbit
              </a>
            </Typography>
          </FooterBottomWrapper>
        </Stack>
      </HeaderWrapper>
    </FooterWrapper>
  );
}

export default Footer;
