import { Box, Button } from '@mui/material';

interface Props {
  onClear: () => void;
  onSave: () => void;
}

const ActionButtons = ({ onClear, onSave }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: 1
      }}
    >
      <Button variant="text" size="small" type="button" onClick={onClear}>
        Clear
      </Button>
      <Button variant="contained" size="small" id="Save" onClick={onSave}>
        Save
      </Button>
    </Box>
  );
};

export default ActionButtons;
