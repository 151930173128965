import React, { FC, memo } from 'react';

import { useImageCheck } from '@/hooks/useImageCheck';

interface ImageValidViewProps {
  path: string;
  name: string;
  defaultPath?: string;
  className?: string;
  style: object;
}

const ImageValidViewComponent: FC<ImageValidViewProps> = ({
  path,
  name,
  style,
  defaultPath,
  className
}) => {
  const validImage = useImageCheck(path);
  return (
    <img
      className={className}
      src={validImage || defaultPath}
      alt={name}
      style={style}
    />
  );
};

ImageValidViewComponent.defaultProps = {
  defaultPath: '',
  className: '',
  style: {}
};

export const ImageValidView = memo(ImageValidViewComponent);
