import { Box, useTheme, useMediaQuery } from '@mui/material';

import { OptionsProps } from '@/components/Filters/types';
import WebVersion from '@/components/Filters/AutocompleteFilter/WebVersion';
import MobileVersion from './MobileVersion';

interface Props {
  filterId: string;
  filterName: string;
  label: string;
  data: OptionsProps[];
  sx?: object;
}

const AutocompleteFilter = ({
  filterId,
  filterName,
  label,
  data,
  sx
}: Props) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ ...sx }}>
      {mobile ? (
        <MobileVersion
          label={label}
          filterId={filterId}
          filterName={filterName}
          data={data}
        />
      ) : (
        <WebVersion
          label={label}
          filterId={filterId}
          filterName={filterName}
          data={data}
        />
      )}
    </Box>
  );
};

export default AutocompleteFilter;
