import React from 'react';

import Router from 'next/router';
import {
  Typography,
  Chip,
  Button,
  useTheme,
  useMediaQuery
} from '@mui/material';

import { useRouterParams } from '@/hooks/useRouterParams';

const ActiveChip = ({ label, removeChip, id }) => {
  return (
    <Chip
      id={id}
      label={<Typography variant="body1">{label}</Typography>}
      sx={{ borderRadius: 1, mr: 1, mb: 1 }}
      variant="outlined"
      onDelete={removeChip}
    />
  );
};

const ListActiveChips = ({ filtersOptions, filtersMapping }) => {
  const { query } = Router;
  const { setParam, clearParams } = useRouterParams();

  const getFilterLabel = (queryKey, queryValue) => {
    if (filtersOptions) {
      const filterOptions = filtersOptions[filtersMapping[queryKey]];

      if (filterOptions) {
        if (Array.isArray(filterOptions)) {
          const foundFilterOption =
            filterOptions?.length &&
            filterOptions.find(
              (option) => option.id === decodeURIComponent(queryValue)
            );

          if (foundFilterOption) {
            return foundFilterOption.name;
          }
        } else {
          const foundFilterOption = Object.keys(filterOptions).find(
            (option) => option === decodeURIComponent(queryValue)
          );

          if (foundFilterOption) {
            return foundFilterOption;
          }
        }
      } else {
        return decodeURIComponent(queryValue);
      }
    }
  };

  return (
    <>
      {Object.keys(query).map((key, index) => {
        if (filtersMapping && filtersMapping[key]) {
          const queryValues = query[key];

          if (Array.isArray(queryValues)) {
            return queryValues.map((queryValue, index) => (
              <ActiveChip
                id={query[key]}
                label={getFilterLabel(key, queryValue)}
                removeChip={() => {
                  const queryArrayWithoutChip = queryValues.filter(
                    (v) => v !== queryValue
                  );

                  const decodedQueryArrayWithoutChip =
                    queryArrayWithoutChip.map((param) =>
                      decodeURIComponent(param)
                    );

                  setParam(key, decodedQueryArrayWithoutChip);
                }}
                key={index}
              />
            ));
          } else {
            return (
              <ActiveChip
                id={query[key]}
                label={getFilterLabel(key, query[key])}
                removeChip={() => clearParams(key)}
                key={index}
              />
            );
          }
        }
      })}
    </>
  );
};

const ActiveFilters = ({ filtersOptions, filtersMapping }) => {
  const { query } = Router;
  const { clearParams } = useRouterParams();

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  const hideActiveFilters = () => {
    let hide = true;
    const queryParams = Object.keys(query);

    if (queryParams.length) {
      queryParams.forEach((queryParam) => {
        if (
          ![
            'page',
            'per_page',
            'q[search]',
            'q[name_or_description_cont]',
            'q[organization_name_or_transaction_name_cont]',
            'q[acquiree_name_or_acquirer_name_or_transaction_name_cont]',
            'q[full_name_or_current_company_position_or_current_company_cont]',
            'q[s]',
            'code',
            'state'
          ].includes(queryParam)
        ) {
          hide = false;
        }
      });
    }

    return hide;
  };

  if (hideActiveFilters()) {
    return null;
  }

  return (
    <>
      {mobile ? (
        <ListActiveChips
          filtersOptions={filtersOptions}
          filtersMapping={filtersMapping}
        />
      ) : (
        <>
          <ListActiveChips
            filtersOptions={filtersOptions}
            filtersMapping={filtersMapping}
          />

          <Button
            variant="text"
            sx={{ ml: 1 }}
            onClick={() => clearParams()}
            id="clearAllFilters"
          >
            Clear All
          </Button>
        </>
      )}
    </>
  );
};

export default ActiveFilters;
