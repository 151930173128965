import { useCallback } from 'react';

import { useRouterParams } from '@/hooks/useRouterParams';

const useDefaultPagination = () => {
  const { hasParam, getParamValue, setMultipleParams } = useRouterParams();

  const page: number = Number(getParamValue('page')) || 1;

  const perPage: number =
    Number(localStorage.getItem('per_page')) ||
    Number(getParamValue('per_page')) ||
    10;

  const addDefaultQueryParams = useCallback(() => {
    if (!hasParam('page') && !hasParam('per_page')) {
      setMultipleParams([
        {
          name: 'page',
          value: page.toString()
        },
        {
          name: 'per_page',
          value: perPage.toString()
        }
      ]);
    }
  }, [hasParam, setMultipleParams, page, perPage]);

  return { addDefaultQueryParams };
};

export default useDefaultPagination;
